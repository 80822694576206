.centurytrain {
  table {
    .td-task, .td-timeleft {
      text-align: center;
    }
    .td-rarity {
      font-size: 0.8em;
    }
  }
}

@media all and (max-width: 479px) {
  .centurytrain td.from, td.to {
      display:none;
      width:0;
      height:0;
      opacity:0;
      visibility: collapse;
  }    
}
