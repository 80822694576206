// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.header {
  font-size: large;
  padding: 0;
  margin: 0;
  display: flex;
  overflow: hidden;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 5;
  
  .container {
    display: flex;
    max-width: 620px;
    min-height: 48px;
    background-color:#D8C3A5;
    border-bottom-left-radius: 10px 10px;
    border-bottom-right-radius: 10px 10px;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 5px;
    font-family: $roboto-slab;
    .account {
      label {
        font-size: x-small;
      }
      display: flex;
      flex-direction: column;
      .user {
        font-size: large;
      }
    }
    .metrics {
      font-size: small;
      span {
        margin: 4px;
      }
    }
    .selected {
      color: $fifth-color;
    }
  }
}