// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.login {
  margin-top: 360px;
  text-align: center;

  button {
    background-color: gray;
    border: none;
    border-radius: 10px;
    padding: 1em;
    
    &:hover {
      cursor: pointer;
      background-color: cyan;
    }

  }

}

#root {
  height: 100%;
}

.fh {
  height: 100%;
}

.game-selection {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.game-card {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $secondary-color;
  width: 100%;
  max-width: 620px;
  padding: 0.6em;
  border-radius: 10px;
  margin-top: 10px;

  h2 {
    font-size: 1.0em;
    width: 70%;
    text-align: left;
    padding-left: 20px;
  }

  label {
    align-self: center;
    font-size: 1.0em;
  }
  
  button {
    align-self: center;
    height: 40px;
  }

}


