// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footer {
  width: 100%;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  justify-content: center;
  display: flex;
  min-height: 64px;
}

.bottom-nav {
  width: 100%;
  max-width: 640px;
  background-color: $secondary-color;
  border-top-left-radius: 10px 10px;
  border-top-right-radius: 10px 10px;
  display: flex;
  justify-content: space-between;
}

.bottom-nav a {
  float: left;
  display: block;
  color: $text-color;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  min-width: 100px;
  flex-grow: 1;
  
}


/* Change the color of links on hover */

.bottom-nav a:hover {
  background-color: $third-color;
  flex-grow: 1;
  color:white;
}

/* Add a color to the active/current link */

.bottom-nav a.active {
  background-color: $fifth-color;
  flex-grow: 1;
  color: white;
}

