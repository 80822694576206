

.staff {
  table {
    .td-task, .td-timeleft {
      text-align: center;
    }
    .td-rarity, .td-stats {
      font-size: 0.8em;
    }
  }
}

@media all and (max-width: 479px) {
  .staff td.td-task, td.td-stats {
      display:none;
      width:0;
      height:0;
      opacity:0;
      visibility: collapse;
  }    
}
