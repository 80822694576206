// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

.timeleft {
  background-color: $fifth-color;
  border-radius: 8px;
  padding: 0.4em 1.0em 0.4em 1.0em;
  font-size: medium;
  color: $primary-color;
}

.timeleft.time-ready {
  background-color: $secondary-color;
  color: $fifth-color;
}

.timeleft.time-far {
  background-color: $fourth-color;
  color: $primary-color;
}

.content {
  padding-top: 56px;
  padding-bottom: 80px;
  width: 100%;
  justify-content: center;
  align-content: center;
}

.bot-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.td-time {
  width: 110px;
}

.td-name {
  width: 160px;
}

.btn {
  background-color: $fifth-color;
  padding: 5px;
  color: $primary-color;
  border: none;
  border-radius: 5px;
  margin: 5px;
  margin-top: 15px;
  padding: 10px;
}


/********** table *****************/

.styled-table {
  border-collapse: collapse;
  margin: 10px 0;
  width: 100%;
  font-weight: 300;
  font-size: 0.8em;
  font-family: $roboto-slab;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  .td-timeleft {
    width: 80px;
  }  
}

.styled-table thead tr {
  background-color: $secondary-color;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid $secondary-color;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.table-container {
  overflow-x:auto;
}

.styled-table tr:nth-of-type(even) { 
  background: $primary-color; 
}

.game-info {
  font-size: medium;
  font-family: $roboto-slab;
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
  margin: 0;
  p {
    margin: 5px 0px;
  }
  strong {
    font-weight: bold;
    color: $fifth-color;
    font-size: 1.0em;
  }
}

.loading {
  text-align: center;
  width: 100%;
  font-size: medium;
  font-family: $roboto-slab;
  strong {
    font-weight: bold;
    color: $fifth-color;
    font-size: 1.0em;
  }
}