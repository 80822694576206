// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300&display=swap');

$roboto-slab: 'Roboto Slab', serif;   
$open-sans: 'Open Sans', sans-serif;
body {
  font-family: $roboto-slab;
  font-weight: thin;
}
h1, h2, h3, h4, h5, h6 {
  font-family: $roboto-slab;
  font-weight: 100;
  line-height: 0.8em;
  margin-bottom: 0.8em;
  margin-top: 0.8em;
}

.button {
  font-family: $roboto-slab;
  font-weight: bold;
}

.small {
  font-size: small;
  font-family: $roboto-slab;
}