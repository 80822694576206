
.farmersworld {
  table {
    strong {
      font-weight: bold;
      color: $fifth-color;
      font-size: 1.0em;
    }
    
    .td-timeleft {
      text-align: center;
    }

    .td-name {
      width: 100%;
    }

    .td-day, td-claim {
      min-width: 80px;
    }

    .td-time {
      min-width: 110px;
    }
  }
}

h5 {
    strong {
        font-weight: bold;
        color: $fifth-color;
        font-size: 1.0em;  
    }
}

@media all and (max-width: 479px) {
  .farmersworld td.td-claim, td.td-day {
      display:none;
      width:0;
      height:0;
      opacity:0;
      visibility: collapse;
  }    
}