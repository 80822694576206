
.bot {
  max-width: 620px;
  align-self: center;
  /*border: 1px solid rgba(142, 141, 138, .5);*/
  border: 1px solid rgba(50,50,50,.1);
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;

  h1 {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 4px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(142, 141, 138, .5);
    span {
      margin-left: 5px;
      font-size: 1.0rem;
      font-weight: normal;
    }
  }
  .ballance {
    display: flex;
    border-radius: 5px;
    background-color: $secondary-color;
    padding: 8px;
    color: black;
    margin-top: 10px;
    margin-bottom: 10px;
    ul li {
      float: left;
      font-family: $roboto-slab;
      font-size: 0.7em;
      font-weight: 100;
      span {
        padding: 0.1rem;
        font-weight: bold;
      }
      margin: 4px;
    }

    ul {
      margin:0;
      padding: 0;
      justify-content: space-between;
    }

  }
}
