
.music {
  table {
    .td-name {
      width: 100%;
    }
  }
}

@media all and (max-width: 479px) {
  .staff td.td-task {
      display:none;
      width:0;
      height:0;
      opacity:0;
      visibility: collapse;
  }    
}
