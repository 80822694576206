
.farmingtales {
  table {
    .td-timeleft {
      text-align: center;
    }
    .td-name {
      width: 100%;
    }
  }
}

@media all and (max-width: 479px) {
  .farmingtales td.td-sest, td.td-food, td.td-water {
      display:none;
      width:0;
      height:0;
      opacity:0;
      visibility: collapse;
  }    
}