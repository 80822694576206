.xland {
  table {
    .td-land {
      width: 100%;
      strong {
        font-weight: bold;
        color: $fifth-color;
        font-size: 1.0em;
      }
    }
  }
}

@media all and (max-width: 479px) {
  .xland td.td-equip {
      display:none;
      width:0;
      height:0;
      opacity:0;
      visibility: collapse;
  }    
}
