
.panda-slot {
  table {
    .td-steps, .td-timeleft, td-level, td-enery {
      text-align: center;
    }
    .td-jewerly, .td-weapon {
        width: 20px;
        font-size: 0.8em;
    }
    tbody  .td-energy, .td-steps {
      font-size: 0.9em;
    }
  }
}

@media all and (max-width: 479px) {
  .panda-slot td.td-steps, td.td-lvl, td.td-weapon, td.td-jewerly {
      display:none;
      width:0;
      height:0;
      opacity:0;
      visibility: collapse;
  }    
}

/* #Tablet (Portrait)
================================================== */
/* Note: Design for a width of 768px */
/*
@media all and (min-width: 768px) and (max-width: 959px) {
  td.col_1{
      display:none;
      width:0;
      height:0;
      opacity:0;
      visibility: collapse;       
  } 
}
*/
/* #Mobile (Landscape)
================================================== */
/* Note: Design for a width of 600px */
/*
@media all and (min-width: 600px) and (max-width: 767px) {
  td.col_2{
      display:none;
      width:0;
      height:0;
      opacity:0;
      visibility: collapse;
  } 
}
*/
/* #Mobile (Landscape)
================================================== */
/* Note: Design for a width of 480px */
/*
@media all and (min-width: 480px) and (max-width: 599px) {
  td.col_3{
      display:none;
      width:0;
      height:0;
      opacity:0;
      visibility: collapse;
  } 
}
*/
/*  #Mobile (Portrait)
================================================== */
/* Note: Design for a width of 320px */
/*
@media all and (max-width: 479px) {
  td.col_4{
      display:none;
      width:0;
      height:0;
      opacity:0;
      visibility: collapse;
  }    
}
*/